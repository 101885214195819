import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-flexbox-grid';
import videojs from 'video.js';
//import ReactPlayer from 'react-player';

export default function PendingVideoPage() {
    const [pendingVideos, setPendingVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isLoading) return;
        axios({
            url: apiUrl('v1', 'admin', 'videos', 'pending'),
            method: 'get'
        })
            .then(response => {
                const { success, message, result: videos } = response.data;
                if (success) {
                    setPendingVideos(videos);
                } else {
                    alert(message);
                }

                setIsLoading(false);
            })
            .catch(err => {
                alert(err);
            });
    }, [
        pendingVideos,
        isLoading
    ]);
    return (
        isLoading ?
            <LoadingState />
            :
            <div>
                {pendingVideos instanceof Array && pendingVideos.length ?
                    pendingVideos.map((video, index) => <VideoCard key={index} video={video}
                        onApprove={() => {
                            setPendingVideos(pendingVideos.filter(v => v.id !== video.id));
                        }}
                        onReject={() => {
                            setPendingVideos(pendingVideos.filter(v => v.id !== video.id));
                        }}
                    />)
                    : <div>No pending videos</div>
                }
            </div>
    )
}

function LoadingState() {
    return (
        <div>Loading...</div>
    );
}

function VideoCard({ video, onApprove, onReject }) {
    const videoRef = useRef(null);
    const { id, title, description, sourceUrl: url, thumbnailUrl } = video;

    useEffect(() => {
        if (videoRef.current) {
            videojs(videoRef.current, {
                sources: [
                    {
                        src: `${url}/720.m3u8`,
						type: 'application/x-mpegURL',
                    }
                ]
            });
        }
    });

    return (
        <div style={{
            display: 'block',
            position: 'relative',
            padding: '1.5em',
        }}>
            <Row>
                <Col md={2}>
                    <video ref={videoRef} className="video-js" width={300} preload="auto" controls poster={thumbnailUrl} style={{
                        backgroundColor: 'black',
                        borderRadius: '12px',
                        height: 200,
                        width: 300,
                    }}>
                    </video>
                </Col>
                <Col md={6} style={{ textAlign: 'left' }}>
                    <h2>Video#{id}</h2>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <div>
                        <button
                            onClick={async () => {
                                onApprove(video);
                                await approveVideoById(id);
                            }}
                        >Approve</button>
                        <button
                            onClick={async () => {
                                onReject(video);
                                await rejectVideoById(id);
                            }}
                        >Reject</button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

function Title({ children }) {
    return (
        children ?
            <h3>{children}</h3>
            :
            <h3><em>NO TITLE</em></h3>
    );
}

function Description({ children }) {
    return (
        children ?
            <div>{children}</div>
            :
            <div><em>NO DESCRIPTION</em></div>
    );
}

async function approveVideoById(id) {
    try {
        await axios({
            url: apiUrl('v1', 'admin', 'videos', id, 'approve'),
            method: 'post'
        });
    } catch (err) {
        alert(err);
    }
}

async function rejectVideoById(id) {
    try {
        await axios({
            url: apiUrl('v1', 'admin', 'videos', id, 'reject'),
            method: 'post'
        });
    } catch (err) {
        alert(err);
    }
}

function apiUrl(...path) {
    let baseUrl = process.env.REACT_APP_API_BASE_URL;
    if (baseUrl && baseUrl.length > 0 && baseUrl[baseUrl.length - 1] !== '/') baseUrl = `${baseUrl}/`;
    return `${baseUrl}${path.join('/')}`;
}
