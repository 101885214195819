import './App.css';
import PendingVideoPage from './PendingVideoPage';

function App() {
  return (
    <div className="App">
      <h1>Fitvids Admin Portal</h1>
      <div id="content">
        <PendingVideoPage />
      </div>
    </div>
  );
}

export default App;
